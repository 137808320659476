import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import axios from 'axios';

interface User {
  UserID: number;
  username: string;
}

interface Group {
  GroupID: number;
  GroupName: string;
}

interface AddToGroupDialogProps {
  open: boolean;
  onClose: () => void;
  user: User;
  groups: Group[];
  onSuccess: (message: string) => void;
}

const AddToGroupDialog: React.FC<AddToGroupDialogProps> = ({
  open,
  onClose,
  user,
  groups,
  onSuccess
}) => {
  const [selectedGroup, setSelectedGroup] = useState<Group>({GroupID: 0, GroupName: ''});
  const [transferFailReason, setTransferFailReason] = useState<string>('');

  useEffect(() => {
    if (open) {
      setTransferFailReason('');
    }
  }, [open]);

  const handleAddUserToGroup = async () => {
    if (selectedGroup) {
      try {
        const response = await axios.post(
          '/api/addUserToGroup',
          { GroupID: selectedGroup.GroupID, UserID: user.UserID},
          {
            headers: {
              'Content-Type': 'application/json',
            },
            withCredentials: true,
          }
        );

        if (response.status === 200) {
          onSuccess('User transferred successfully!');
          onClose();
        }
      } catch (error) {
        if (axios.isAxiosError(error)) {
          if (error.response && error.response.status === 409) {
            setTransferFailReason('User is already in the selected group.');
          } else if (error.response) {
            setTransferFailReason(`${error.response.data.error || 'Unknown error'}`);
          } else if (error.request) {
            setTransferFailReason('No response received from the server.');
          } else {
            setTransferFailReason('Error in setting up the request.');
          }
        } else {
          setTransferFailReason('Unexpected error occurred.');
        }
      }
    }
  };

return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>Transfer Group</DialogTitle>
        <DialogContent>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Select the group to transfer <strong>{user.username}</strong> to:
            </Typography>
            <FormControl fullWidth variant="outlined" sx={{ mt: 2, mb: 2 }}>
                <InputLabel id="group-select-label">Group</InputLabel>
                <Select
                    labelId="group-select-label"
                    value={selectedGroup}
                    onChange={(e) => {                        
                        setSelectedGroup(groups.find(group => group.GroupName === e.target.value as string) || { GroupID: 0, GroupName: '' });
                    }}
                    label="Group"
                >
                    {groups.map((group) => (
                        <MenuItem key={group.GroupID} value={group.GroupName}>
                            {group.GroupName}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <Typography variant="subtitle2" sx={{ mb: 2 }}>
                This action will remove the user from the current group and add them to the selected group.
            </Typography>
        </DialogContent>
        {transferFailReason && (
            <Typography color="error" variant="body2" align="center" sx={{ mt: 2 }}>
                {transferFailReason}
            </Typography>
        )}
        <DialogActions sx={{ justifyContent: "space-between", padding: 2 }}>
            <Button onClick={onClose} variant="outlined" color="secondary">
                Cancel
            </Button>
            <Button onClick={handleAddUserToGroup} variant="contained" color="primary" disabled={!selectedGroup}>
                Transfer
            </Button>
        </DialogActions>
    </Dialog>
);
};

export default AddToGroupDialog;
