import axios from "axios";
import * as forge from "node-forge";

async function fetchPublicKey(): Promise<string> {
  try {
    const response = await axios.get("/api/getPublicKey");
    return response.data;
  } catch (error) {
    console.error("Error fetching public key:", error);
    throw new Error("Failed to fetch public key");
  }
}

export async function fetchNonce(): Promise<string> {
  try {
    const response = await axios.get("/api/auth/getNonce");
    return response.data;
  } catch (error) {
    console.error("Error fetching nonce:", error);
    throw new Error("Failed to fetch nonce");
  }
}

export default async function encryptData(data: object): Promise<string> {
  try {
    const publicKeyPem = await fetchPublicKey();
    const publicKey = forge.pki.publicKeyFromPem(publicKeyPem);
    const plaintext = JSON.stringify(data);
    const encrypted = publicKey.encrypt(plaintext, "RSA-OAEP");
    return forge.util.encode64(encrypted);
  } catch (error) {
    console.error("Error encrypting data:", error);
    throw new Error("Failed to encrypt data");
  }
}
