import React, { useContext } from "react";
import { Button, Typography, IconButton } from "@mui/material";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../index";
import LogoutIcon from '@mui/icons-material/Logout';

interface SignOutButtonProps {
  type: string
}

const SignOutButton: React.FC<SignOutButtonProps> = ({ type }) => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);

  if (!authContext) {
    throw new Error("AuthContext must be used within an AuthProvider");
  }

  const { setIsAuthenticated } = authContext;

  const handleLogout = async () => {
    try {
      await axios.get("/api/user/logout", {
        withCredentials: true,
      });
      setIsAuthenticated(false);
      navigate("/");
      console.log("Logout successful");
    } catch (error) {
      console.error("Logout error:", error);
    }
  };
  if (type === "mobile") {
    return (
      <IconButton onClick={handleLogout}>
        <LogoutIcon />
      </IconButton>
    );
  } else {
    return (
      <Button
        variant="contained"
        color="inherit"
        onClick={handleLogout}
        sx={{ textTransform: 'none', color: 'inherit', mr: '8px', ml: '8px' }}
      >
        <LogoutIcon sx={{ mr: 1 }} />
        <Typography>Sign Out</Typography>
      </Button>
    )
  }
};

export default SignOutButton;
