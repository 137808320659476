import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import axios from 'axios';


interface DeleteGroupDialogProps {
  open: boolean;
  groupID: number;
  groupName: string;
  onClose: () => void;
  onSuccess: (message: string) => void;
}

const DeleteGroupDialog: React.FC<DeleteGroupDialogProps> = ({
  open,
  groupID,
  groupName,
  onClose,
  onSuccess
}) => {
  const [failReason, setFailReason] = useState<string>('');

  const handleDeleteGroup = async () => {
    try {
      const response = await axios.post(
        '/api/deleteGroup',
        { groupID },
        {
          headers: {
            'Content-Type': 'application/json',
          },
          withCredentials: true,
        }
      );

      if (response.status === 200) {
        onSuccess('Group Deleted successfully!');
        onClose();
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          setFailReason(`${error.response.data.error || 'Unknown error'}`);
        } else if (error.request) {
          setFailReason('No response received from the server.');
        } else {
          setFailReason('Error in setting up the request.');
        }
      } else {
        setFailReason('Unexpected error occurred.');
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Delete Group</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete the group: {groupName}?
        </Typography>
        {failReason && (
          <Typography color="error" variant="body2" align="center" sx={{ mt: 2 }}>
            {failReason}
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleDeleteGroup}>Delete</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteGroupDialog;
