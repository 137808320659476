import React, { useContext, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import { themeToggle, AuthContext } from '../index';
import { useTheme } from '@emotion/react';
import { CustomTheme } from '../types/types';
import DesktopMenu from '../Components/Header/DesktopMenu';
import MobileMenu from '../Components/Header/MobileMenu';

interface PagesProps{
  path : string,
  name : string
}
const Header: React.FC = () => {
  const [navMenu, setNavMenu] = useState<HTMLElement | null>(null);
  const { toggleColorMode } = useContext(themeToggle);
  const authContext = useContext(AuthContext);
  const [pages, setPages] = useState<PagesProps[]>([])

  if (!authContext) {
    throw new Error('AuthContext must be used within an AuthProvider');
  }

  const { isAuthenticated, isAdmin } = authContext;

  const theme = useTheme() as CustomTheme;

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLButtonElement>) => setNavMenu(event.currentTarget);
  const handleCloseNavMenu = () => setNavMenu(null);

  useEffect(() => {
    const getPages = ()  => {
      if (isAdmin !== "User") {
        setPages([{path:'Admin', name:'Admin'}])
      } else {
        setPages([])
      }
    };
  
    getPages();
  }, [isAdmin]);

  return (
    <Box
      id="header"
      alignItems="center"
      sx={{
        backgroundColor: theme.palette.background.default,
        color: theme.palette.text.primary,
        padding: '0 16px',
      }}
    >
      <MobileMenu
        navMenu={navMenu}
        handleOpenNavMenu={handleOpenNavMenu}
        handleCloseNavMenu={handleCloseNavMenu}
        pages={pages}
        toggleColorMode={toggleColorMode}
        theme={theme}
        isAuthenticated={isAuthenticated}
      />
        <DesktopMenu
          pages={pages}
          toggleColorMode={toggleColorMode}
          theme={theme}
          isAuthenticated={isAuthenticated}
        />
    </Box>
  );
};

export default Header;
