import React, { useContext, useState } from 'react';
import { Button, Typography, Container, Box, Grid, TextField, Link, Checkbox, FormControlLabel, CssBaseline, IconButton, InputAdornment } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import axios from 'axios';
import encryptData, { fetchNonce } from '../../Security/encryption';
import { AuthContext, AuthResponse } from '../../index';
import { useNavigate, useLocation } from 'react-router-dom';

export default function LoginForm() {
    const navigate = useNavigate();
    const authContext = useContext(AuthContext);
    const [failReason, setFailReason] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const location = useLocation();
    const successMessage = location.state?.successMessage || '';

    if (!authContext) {
        throw new Error("AuthContext must be used within an AuthProvider");
    }
    
    const context = useContext(AuthContext);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        const email = data.get('email') as string;
        const password = data.get('password') as string;
        const remember = data.get('remember');
        console.log(remember)

        if (!email || !password) {
            setFailReason("Please provide both email and password.");
            return;
        }

        const nonce = await fetchNonce();

        try {
            const formData = {
                email: email,
                password: password,
                nonce: nonce,
                remember: remember
            };

            const encryptedData = await encryptData(formData);

            const response = await axios.post("/api/UserLogin", { encryptedData: encryptedData }, {
                headers: {
                    "Content-Type": "application/json",
                },
                withCredentials: true
            });

            const results = response.data;
            if (results.success) {
                const verifyResponse = await fetch('/api/auth/verify', {
                    method: 'GET',
                    credentials: 'include',
                });
                const data: AuthResponse = await verifyResponse.json();
                context?.setIsAuthenticated(true);
                context?.setIsAdmin(data.isAdmin)
                navigate('/admin');
            } else {
                setFailReason(results.error || "Login failed.");
            }

        } catch (error) {
            if (axios.isAxiosError(error)) {
                if (error.response) {
                    setFailReason(`${error.response.data.error || 'Unknown error'}`);
                } else if (error.request) {
                    setFailReason('No response received from the server.');
                } else {
                    setFailReason('Error in setting up the request.');
                }
            } else {
                setFailReason('Unexpected error occurred.');
            }
        }
    };

    const handleClickShowPassword = () => setShowPassword(prev => !prev);

    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box
                    sx={{
                        padding: 4,
                        borderRadius: 2,
                        boxShadow: 1,
                        bgcolor: 'background.paper',
                        width: '100%',
                        maxWidth: 400
                    }}
                >
                    <Typography component="h1" variant="h5" align="center" gutterBottom>
                        Sign in
                    </Typography>
                    <Typography variant="body2" color="textSecondary" align="center" gutterBottom>
                        {successMessage}
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            type="email"
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type={showPassword ? "text" : "password"}
                            id="password"
                            autoComplete="current-password"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPassword}
                                            edge="end"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <FormControlLabel
                            control={<Checkbox value="remember" name="remember" color="primary" />}
                            label="Remember me"
                        />

                        {failReason && (
                            <Typography color="error" variant="body2" align="center" sx={{ mt: 2 }}>
                                {failReason}
                            </Typography>
                        )}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            color="primary"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </Button>
                        <Grid container>
                            <Grid item xs>
                                <Link href="#" variant="body2" underline="hover">
                                    Forgot password?
                                </Link>
                            </Grid>
                            <Grid item>
                                <Link href="/SignUp" variant="body2" underline="hover">
                                    {"Don't have an account? Sign Up"}
                                </Link>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
}
