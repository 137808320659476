import React, { useState, useEffect } from 'react';
import Layout from '../../Layout/Layout';
import { Box, Typography, Card, CardContent, Button, Snackbar, Alert, CardActions, Grid, Pagination, Divider, IconButton, Tooltip } from '@mui/material';
import axios from 'axios';
import CreateGroupDialog from '../../Components/Admin/GroupManagement/CreateGroupDialog';
import { Delete, People, PersonAdd } from '@mui/icons-material';
import ViewUsersDialog from '../../Components/Admin/GroupManagement/ViewUsersDialog';
import DeleteGroupDialog from '../../Components/Admin/GroupManagement/DeleteGroupDialog';

interface Group {
  GroupID: number;
  GroupName: string;
  GroupDescription: string;
  users?: number;
}

interface APIResponse {
  groups: Group[];
}

const useGroupsData = () => {
  const [groups, setGroups] = useState<Group[]>([]);
  const [error, setError] = useState<string | null>(null);

  const fetchGroups = async () => {
    try {
      const response = await axios.get<APIResponse>('/api/groups', {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });
      setGroups(response.data.groups);
    } catch (error) {
      setError(
        axios.isAxiosError(error) && error.response
          ? `API Error: ${error.response.status} ${error.response.statusText}`
          : 'Unexpected Error occurred'
      );
    }
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  return { groups, error, refetch: () => fetchGroups() };
};

const GroupManagementPage: React.FC = () => {
  const { groups, error, refetch } = useGroupsData();
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [snackbarMessage, setSnackbarMessage] = useState<string>('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
  const [createGroupDialog, setCreateGroupDialog] = useState<boolean>(false);
  const [groupID, setGroupID] = useState<number | null | "All">(null);
  const [groupName, setGroupName] = useState<string>('');
  const [viewUsersDialog, setViewUsersDialog] = useState<boolean>(false);
  const [openDeleteGroupDialog, setOpenDeleteGroupDialog] = useState<boolean>(false);

  const handleOpenCreateGroupDialog = () => {
    setCreateGroupDialog(true);
  };

  const handleCloseCreateGroupDialog = () => {
    setCreateGroupDialog(false);
  };

  const handleOpenViewUsersDialog = (groupID: number | null | 'All') => {
    setGroupID(groupID);
    setViewUsersDialog(true);
  };

  const handleCloseViewUsersDialog = () => {
    setViewUsersDialog(false);
    setGroupID(null);
  };

  const handleDialogSuccess = (message: string) => {
    refetch();
    setSnackbarMessage(message);
    setSnackbarSeverity('success');
    setOpenSnackbar(true);
  };

  const handleOpenDeleteGroupDialog = (groupID: number, groupName: string) => {
    setGroupID(groupID);
    setGroupName(groupName);
    setOpenDeleteGroupDialog(true);
  };

  const handleCloseDeleteGroupDialog = () => {
    setOpenDeleteGroupDialog(false);
  };

  const cardsPerPage = 6;
  const [page, setPage] = useState(1);

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const paginatedGroups = groups.slice((page - 1) * cardsPerPage, page * cardsPerPage);

  return (
    <Layout>
      <Box mt={3} textAlign="center" sx={{ px: 3 }}>

        <Typography variant="h4" gutterBottom>
          Group Management
        </Typography>

        <Typography variant="subtitle1" paragraph>
          Manage groups, including creating, editing, and deleting groups.
        </Typography>

        <Box display="flex" mb={2}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<PersonAdd />}
            onClick={handleOpenCreateGroupDialog}
            sx={{ borderRadius: 2 }}
          >
            Create New Group
          </Button>
        </Box>

        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Box mb={2}>
              <Card sx={{ borderRadius: 2, height: 200 }}>
                <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                  <Typography variant="h6">All Users</Typography>
                  <Typography variant="body2" color="textSecondary">
                    View all users in the system.
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: 'center' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<People />}
                    onClick={() => handleOpenViewUsersDialog("All")}
                    sx={{ mt: 2, borderRadius: 2 }}
                  >
                    View All Users
                  </Button>
                </CardActions>
              </Card>
            </Box>

            <Box mb={2}>
              <Card key="Unassigned" sx={{ borderRadius: 2, height: 200 }}>
                <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                  <Typography variant="h6">Unassigned</Typography>
                  <Typography variant="body2" color="textSecondary">
                    All Unassigned Users
                  </Typography>
                </CardContent>
                <CardActions sx={{ justifyContent: 'center' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    startIcon={<People />}
                    onClick={() => handleOpenViewUsersDialog(null)}
                    sx={{ mt: 2, borderRadius: 2 }}
                  >
                    View Unassigned Users
                  </Button>
                </CardActions>
              </Card>
            </Box>
          </Grid>

          <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />

          <Grid item xs={9}>
            <Grid container spacing={2}>
              {paginatedGroups.map((group) => (
                <Grid item xs={4} key={group.GroupID}>
                  <Card sx={{ borderRadius: 2, height: 200, position: 'relative' }}>
                    <Tooltip title="Delete Group" arrow>
                    <IconButton
                      color="error"
                      onClick={() => handleOpenDeleteGroupDialog(group.GroupID, group.GroupName)}
                      sx={{ position: 'absolute', top: 8, right: 8 }}
                    >
                      <Delete />
                    </IconButton>
                    </Tooltip>
                    <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                      <Typography variant="h6">{group.GroupName}</Typography>
                      <Typography variant="body2" color="textSecondary">
                        Description: {group.GroupDescription}
                      </Typography>
                      <Typography variant="caption" color="textSecondary">
                        {group.users} user(s)
                      </Typography>
                    </CardContent>
                    <CardActions sx={{ justifyContent: 'center' }}>
                      <Button
                        variant="contained"
                        color="primary"
                        startIcon={<People />}
                        onClick={() => handleOpenViewUsersDialog(group.GroupID)}
                        sx={{ mt: 2, borderRadius: 2, justifyContent: 'center' }}
                      >
                        View Users
                      </Button>
                    </CardActions>
                  </Card>
                </Grid>
              ))}
            </Grid>
          <Box mt={2} display="flex" justifyContent="center">
            <Pagination
              count={Math.ceil(groups.length / cardsPerPage)}
              page={page}
              onChange={handlePageChange}
              color="primary"
            />
          </Box>
          {(error &&
            <Typography>
              {error}
            </Typography>
          )}
          </Grid>
          </Grid>
        

      <CreateGroupDialog
        open={createGroupDialog}
        onClose={handleCloseCreateGroupDialog}
        onSuccess={handleDialogSuccess}
      />

      <ViewUsersDialog
        open={viewUsersDialog}
        onClose={handleCloseViewUsersDialog}
        groups={groups}
        groupID={groupID}
        onSuccess={handleDialogSuccess}
      />

      <DeleteGroupDialog
        open={openDeleteGroupDialog}
        groupID={groupID as number}
        groupName={groupName}
        onClose={handleCloseDeleteGroupDialog}
        onSuccess={handleDialogSuccess}
      />

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        action={
          <Button color="inherit" onClick={() => setOpenSnackbar(false)}>
            Close
          </Button>
        }
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
    </Layout >
  );
};

export default GroupManagementPage;
