import React from "react";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PostAddIcon from '@mui/icons-material/PostAdd';

const SignUpButton: React.FC = () => {
    const navigate = useNavigate();

    function handleNavigation() {
        navigate(`/SignUp`);
    }

    return (
        <Button variant="contained" color="inherit" onClick={handleNavigation} sx={{ textTransform: 'none', color: 'inherit', mr: '8px', ml: '8px' }}>
            <PostAddIcon sx={{ mr: 1 }} />
            <Typography>Sign Up</Typography>
        </Button>
    )
}

export default SignUpButton;
