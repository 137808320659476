import React, { useState, useEffect, ChangeEvent, MouseEvent } from 'react';
import {
  Box, Typography, Button, TextField, Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, TablePagination, Paper, IconButton, Tooltip, Snackbar, Alert,
  Checkbox, useTheme
} from '@mui/material';
import {
  Refresh, Approval, Delete, LockReset, AdminPanelSettings, PersonAdd, DeleteSweep
} from '@mui/icons-material';
import axios from 'axios';
import Layout from '../../Layout/Layout';
import CreateUser from '../../Components/Admin/UserManagement/CreateUsers';
import ActionDialog from '../../Components/Admin/UserManagement/UserManagementActions';
import DeleteSelectedUsersDialog from '../../Components/Admin/UserManagement/DeleteSelectedUsers';

interface UserData {
  UserID: number;
  FirstName: string;
  LastName: string;
  UserEmail: string;
  PasswordChanged: boolean;
  IsAdmin: string;
}

interface ApiResponse {
  data: UserData[];
  email: string;
}

const useUsersData = () => {
  const [usersData, setUsersData] = useState<UserData[]>([]);
  const [userEmail, setUserEmail] = useState<string>("");
  const [error, setError] = useState<string | null>(null);

  const fetchUsersData = async () => {
    try {
      const response = await axios.get<ApiResponse>('/api/ManagedUsers', {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });
      setUserEmail(response.data.email);
      setUsersData(response.data.data);
    } catch (error) {
      if (axios.isAxiosError(error) && error.response) {
        setError(`API Error: ${error.response.status} ${error.response.statusText}`);
      } else {
        setError('Unexpected Error occurred');
      }
    }
  };

  useEffect(() => {
    fetchUsersData();
  }, []);

  return { usersData, userEmail, error, refetch: fetchUsersData };
};

const UserManagement: React.FC = () => {
  const [dialogType, setDialogType] = useState<'reset' | 'delete' | 'approve' | 'promote' | null>(null);
  const [isCreateUserModalOpen, setCreateUserModalOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState<{ id: number; username: string } | null>(null);
  const [selectedUsers, setSelectedUsers] = useState<Set<number>>(new Set());
  const [snackbarMessage, setSnackbarMessage] = useState<string | null>(null);
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('error');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [searchText, setSearchText] = useState('');
  const [page, setPage] = useState(0);
  const theme = useTheme();

  const { usersData, userEmail, error, refetch } = useUsersData();

  const handleOpenCreateUserModal = () => setCreateUserModalOpen(true);
  const handleCloseCreateUserModal = () => setCreateUserModalOpen(false);

  const closeDeleteSelectedDialog = () => setDeleteDialogOpen(false);
  const openDeleteSelectedDialog = () => setDeleteDialogOpen(true);

  const handleDeleteSelectedSuccess = () => {
    setSnackbarMessage('Selected users have been deleted successfully');
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
    refetch();
  };

  const handleDeleteSelectedFailure = () => {
    setSnackbarMessage('Failed to delete selected users');
    setSnackbarSeverity('error');
    setSnackbarOpen(true);
  };

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value.toLowerCase());
    setPage(0);
  };

  const handleChangePage = (event: MouseEvent<HTMLButtonElement> | null, newPage: number) => setPage(newPage);

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const filteredRows = usersData.filter((row) =>
    row.FirstName.toLowerCase().includes(searchText) || row.LastName.toLowerCase().includes(searchText)
  );

  const paginatedRows = filteredRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleSelectUser = (userId: number) => {
    setSelectedUsers(prevSelected => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(userId)) {
        newSelected.delete(userId);
      } else {
        newSelected.add(userId);
      }
      return newSelected;
    });
  };

  const handleChangeSelectAll = () => {
    setSelectedUsers(prevSelected => {
      const newSelected = new Set<number>();
      if (prevSelected.size === filteredRows.length - 1) {
        return newSelected;
      } else {
        filteredRows.forEach(row => {
          if (row.UserEmail !== userEmail) {
            newSelected.add(row.UserID);
          }
        });
        return newSelected;
      }
    });
  };

  const refreshData = async () => {
    refetch()
    setSnackbarMessage(`Table has been refreshed`);
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
  }

  const handleOpenDialog = (type: 'reset' | 'delete' | 'approve' | 'promote', user: { id: number; username: string }) => {
    setDialogType(type);
    setSelectedUser(user);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setDialogType(null);
    setSelectedUser(null);
  };

  const handleCloseSnackbar = () => setSnackbarOpen(false);

  const handleActionSuccess = () => {
    let message = 'Operation successful';
    switch (dialogType) {
      case 'reset':
        message = 'Password has been reset successfully';
        break;
      case 'delete':
        message = 'User has been deleted successfully';
        break;
      case 'approve':
        message = 'Admin request has been approved successfully';
        break;
      default:
        break;
    }
    setSnackbarMessage(message);
    setSnackbarSeverity('success');
    setSnackbarOpen(true);
    refetch();
  };

  return (
    <Layout>
      <Box sx={{ p: 2, overflowX: 'auto', maxWidth: '100%' }}>
        <Typography variant="h4" gutterBottom>
          User Management
        </Typography>

        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: 2,
            alignItems: { xs: 'flex-start', sm: 'center' },
            justifyContent: { xs: 'flex-start', sm: 'flex-end' },
            flexWrap: 'wrap',
            mb: 2,
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="body1">
              Total Users: {filteredRows.length}
            </Typography>
          </Box>
          <TextField
            label="Search Users"
            variant="outlined"
            size="small"
            onChange={handleSearchChange}
            fullWidth
            sx={{ mb: { xs: 2, sm: 0 }, flexShrink: 0, width: { xs: '100%', sm: 'auto' } }}
          />

          <Button
            variant="contained"
            color="primary"
            onClick={handleOpenCreateUserModal}
            fullWidth
            sx={{ mt: { xs: 1, sm: 0 }, flexShrink: 0, width: { xs: '100%', sm: 'auto' } }}
          >
            <PersonAdd sx={{ mr: 1 }} />
            <Typography>Create Users</Typography>
          </Button>
        </Box>
        {error ? (
          <Typography color="error" sx={{ mb: 2 }}>
            Error: {error}
          </Typography>
        ) : (
          <Box sx={{ overflowX: 'auto', maxWidth: 'auto' }}>
            <Paper sx={{ backgroundColor: theme.palette.background.default }}>
              <TableContainer
                sx={{ borderRadius: 2, minWidth: 320, maxWidth: 'auto', overflowX: 'auto' }}
              >
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={filteredRows.length > 0 && filteredRows.length === selectedUsers.size}
                          indeterminate={selectedUsers.size > 0 && selectedUsers.size < filteredRows.length}
                          onChange={handleChangeSelectAll}
                        />
                      </TableCell>
                      <TableCell>First Name</TableCell>
                      <TableCell>Last Name</TableCell>
                      <TableCell>Email</TableCell>
                      <TableCell>Password Status</TableCell>
                      <TableCell>Role</TableCell>
                      <TableCell align="right">
                        <Box display="flex" justifyContent="flex-end" gap={1}>
                          <Tooltip title="Delete Selected Users" arrow>
                            <IconButton
                              size="small"
                              onClick={openDeleteSelectedDialog}
                              disabled={selectedUsers.size === 0}
                            >
                              <DeleteSweep />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Refresh Table" arrow>
                            <IconButton
                              size="small"
                              color="info"
                              onClick={refreshData}
                            >
                              <Refresh />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {paginatedRows.map((row) => (
                      <TableRow key={row.UserID}>
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={selectedUsers.has(row.UserID)}
                            onChange={() => handleSelectUser(row.UserID)}
                            disabled={row.UserEmail === userEmail}
                          />
                        </TableCell>
                        <TableCell>{row.FirstName}</TableCell>
                        <TableCell>{row.LastName}</TableCell>
                        <TableCell>{row.UserEmail}</TableCell>
                        <TableCell>{row.PasswordChanged ? 'Updated' : 'Initial'}</TableCell>
                        <TableCell>{row.IsAdmin}</TableCell>
                        <TableCell align="right">
                          <Box display="flex" justifyContent="flex-end" gap={1}>
                            {row.IsAdmin === "Pending" && (
                              <Tooltip title="Approve Admin Request" arrow>
                                <IconButton
                                  size="small"
                                  color="primary"
                                  onClick={() => handleOpenDialog('approve', { id: row.UserID, username: `${row.FirstName} ${row.LastName}` })}
                                >
                                  <Approval />
                                </IconButton>
                              </Tooltip>
                            )}
                            {row.IsAdmin === "User" && (
                              <Tooltip title="Promote to Admin" arrow>
                                <IconButton
                                  size="small"
                                  color="primary"
                                  onClick={() => handleOpenDialog('promote', { id: row.UserID, username: `${row.FirstName} ${row.LastName}` })}
                                >
                                  <AdminPanelSettings />
                                </IconButton>
                              </Tooltip>
                            )}
                            {row.UserEmail !== userEmail && (
                              <>
                                <Tooltip title="Reset password" arrow>
                                  <IconButton
                                    size="small"
                                    color="secondary"
                                    onClick={() => handleOpenDialog('reset', { id: row.UserID, username: `${row.FirstName} ${row.LastName}` })}
                                  >
                                    <LockReset />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title="Delete user" arrow>
                                  <IconButton
                                    size="small"
                                    color="error"
                                    onClick={() => handleOpenDialog('delete', { id: row.UserID, username: `${row.FirstName} ${row.LastName}` })}
                                  >
                                    <Delete />
                                  </IconButton>
                                </Tooltip>
                              </>
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>

                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  component="div"
                  count={filteredRows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                  sx={{ mt: 2 }}
                />
              </TableContainer>
            </Paper>
          </Box>
        )}

        <CreateUser open={isCreateUserModalOpen} onClose={handleCloseCreateUserModal} refetch={refetch} />

        {selectedUser && dialogType && (
          <ActionDialog
            open={dialogOpen}
            onClose={handleCloseDialog}
            user={selectedUser}
            actionType={dialogType}
            onActionSuccess={handleActionSuccess}
          />
        )}

        {selectedUsers && <DeleteSelectedUsersDialog
          open={deleteDialogOpen}
          onClose={closeDeleteSelectedDialog}
          selectedUsers={selectedUsers}
          onActionSuccess={handleDeleteSelectedSuccess}
          onActionFailure={handleDeleteSelectedFailure}
        />
        }

        <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleCloseSnackbar}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity} sx={{ width: '100%' }}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      </Box>
    </Layout>
  );
};

export default UserManagement;
