import React, { useState } from 'react';
import Layout from '../../Layout/Layout';
import { Box, Typography, Button, Snackbar, Alert } from '@mui/material';
import CreateEventDialog from '../../Components/Admin/EventManagement/CreateEventDialog';
import { Event } from '@mui/icons-material';

const EventManagement: React.FC = () => {
  const [openCreateEventDialog, setOpenCreateEventDialog] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');

  const handleOpenCreateEventDialog = () => {
    setOpenCreateEventDialog(true);
  };

  const handleCloseCreateEventDialog = () => {
    setOpenCreateEventDialog(false);
  }

  const handleSuccess = (message: string) => {
    setSnackbarMessage(message);
    setSnackbarSeverity('success');
    setOpenSnackbar(true);
  }

  return (
    <Layout>
      <Box mt={3} textAlign="center">
        <Typography variant="h4" gutterBottom>
          Event Management
        </Typography>
        <Typography variant="subtitle1" paragraph>
          Create and manage events, including setting details and tokens.
        </Typography>
        <Box display="flex" mb={2}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Event />}
            onClick={handleOpenCreateEventDialog}
            sx={{ borderRadius: 2 }}
          >
            Create New Event
          </Button>
        </Box>
      </Box>
      <CreateEventDialog onSuccess={handleSuccess} open={openCreateEventDialog} onClose={handleCloseCreateEventDialog} />

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => setOpenSnackbar(false)}
        action={
          <Button color="inherit" onClick={() => setOpenSnackbar(false)}>
            Close
          </Button>
        }
      >
        <Alert onClose={() => setOpenSnackbar(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Layout>
  );
};

export default EventManagement;
