import React, { useState } from "react";
import { Box, Button, Typography, List, ListItem, ListItemText, Card, CardContent, Divider, Tabs, Tab, Dialog, DialogTitle, DialogContent, DialogActions, CircularProgress } from "@mui/material";
import { PersonAdd, Email, ErrorOutline } from "@mui/icons-material";
import TextInput from "./TextInput";
import CSVFileInput from "./CSVFileInput";
import DataTable from "./DataTable";
import encryptData from "../../../Security/encryption";
import axios from "axios";

interface ResponseData {
  message: string;
  existingEmails: string[];
  newUsers: { firstName: string; lastName: string; UserEmail: string }[];
  failedToAdd: { name: string; UserEmail: string; reason: string }[];
}

interface CreateUserProps {
  open: boolean;
  onClose: () => void;
  refetch: () => void;
}

const CreateUser: React.FC<CreateUserProps> = ({ open, onClose, refetch }) => {
  const [response, setResponse] = useState<ResponseData | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [textData, setTextData] = useState<string>("");
  const [fileData, setFileData] = useState<string>("");
  const [tabValue, setTabValue] = useState<number>(0);
  const [textValidData, setTextValidData] = useState<{ name: string; email: string }[]>([]);
  const [fileValidData, setFileValidData] = useState<{ name: string; email: string }[]>([]);
  const [loading, setLoading] = useState(false);
  const [created, setCreated] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    setError(null);

    try {
      const validData = tabValue === 0 ? textValidData : fileValidData;
      const encryptedData = await encryptData(validData);
      const { data } = await axios.post<ResponseData>(
        "/api/createUsers",
        { encryptedData },
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      setResponse(data);
      refetch();
    } catch (error: any) {
      setError(`Error: ${error.message}`);
    } finally {
      setLoading(false);
      setCreated(true);
    }
  };

  const renderCard = ({ title, icon: Icon, items, itemContent }: {
    title: string;
    icon: React.ElementType;
    items: any[];
    itemContent: (item: any) => { primary: string; secondary: string };
  }) => {
    if (items && items.length > 0) {
      return (
        <Card variant="outlined" sx={{ mt: 3 }}>
          <CardContent>
            <Typography variant="h6" sx={{ mb: 2 }}>
              <Icon sx={{ verticalAlign: "bottom", mr: 1 }} />
              {title}: {items.length}
            </Typography>
            <List>
              {items.map((item, index) => (
                <ListItem key={index} sx={{ pl: 0 }}>
                  <ListItemText
                    primary={itemContent(item).primary}
                    secondary={itemContent(item).secondary}
                  />
                </ListItem>
              ))}
            </List>
          </CardContent>
        </Card>
      );
    }
    return null;
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Create Users</DialogTitle>
      <DialogContent>
        <Tabs value={tabValue} onChange={(e, newValue) => setTabValue(newValue)} centered>
          <Tab label="Text Input" />
          <Tab label="CSV Input" />
        </Tabs>
        <Box mt={3}>
          {tabValue === 0 ? <TextInput onData={setTextData} /> : <CSVFileInput onData={setFileData} />}
        </Box>
        <Box mt={3}>
          <DataTable
            data={tabValue === 0 ? textData : fileData}
            setData={tabValue === 0 ? setTextValidData : setFileValidData}
          />
        </Box>
        {!created && (
          <Box mt={3} sx={{ display: 'flex', alignItems: 'center' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={loading}
              startIcon={loading ? <CircularProgress size={24} /> : <PersonAdd />}
            >
              <Typography>{loading ? 'Creating...' : 'Create Users'}</Typography>
            </Button>
            {error && (
              <Typography color="error" sx={{ ml: 2 }}>
                {error}
              </Typography>
            )}
          </Box>
        )}
        
        <Divider sx={{ my: 3 }} />

        {renderCard({
          title: 'New Users Added',
          icon: PersonAdd,
          items: response?.newUsers || [],
          itemContent: user => ({
            primary: `${user.firstName} ${user.lastName}`,
            secondary: `Email: ${user.UserEmail}`
          })
        })}
        
        {renderCard({
          title: 'Existing Emails',
          icon: Email,
          items: response?.existingEmails || [],
          itemContent: email => ({
            primary: email,
            secondary: ''
          })
        })}
        
        {renderCard({
          title: 'Failed to Add Users',
          icon: ErrorOutline,
          items: response?.failedToAdd || [],
          itemContent: failure => ({
            primary: `Name: ${failure.name}`,
            secondary: `Email: ${failure.UserEmail}, Reason: ${failure.reason}`
          })
        })}
      
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateUser;
