import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  Box,
  CssBaseline,
  Container,
} from '@mui/material';
import axios from 'axios';

interface CreateGroupDialogProps {
  open: boolean;
  onClose: () => void;
  onSuccess: (message: string) => void;
}

const CreateEventDialog: React.FC<CreateGroupDialogProps> = ({ open, onClose, onSuccess }) => {
  const [failReason, setFailReason] = useState<string>('');

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const eventName = data.get('eventName') as string;
    const eventDescription = data.get('eventDescription') as string;
    const eventDate = data.get('eventDate') as string;

    if (!eventName || !eventDescription) {
      setFailReason('Please provide both event name and description.');
      return;
    }

    try {
      const response = await axios.post('/api/createEvent', { EventName: eventName, EventDescription: eventDescription, EventDate: eventDate }, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });

      if (response.status === 200) {
        onSuccess('Event created successfully!');
        onClose();
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          setFailReason(`${error.response.data.error || 'Unknown error'}`);
        } else if (error.request) {
          setFailReason('No response received from the server.');
        } else {
          setFailReason('Error in setting up the request.');
        }
      } else {
        setFailReason('Unexpected error occurred.');
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Create New Group</DialogTitle>
      <DialogContent>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="eventName"
              label="Event Name"
              name="eventName"
              type="text"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="eventDescription"
              label="Event Description"
              name="eventDescription"
              type="text"
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="eventDate"
              label="Event Date"
              name="eventDate"
              type="date"
              InputLabelProps={{
                shrink: true,
              }} />
            {failReason && (
              <Typography color="error" variant="body2" align="center" sx={{ mt: 2 }}>
                {failReason}
              </Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
            >
              Create Event
            </Button>
          </Box>
        </Container>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateEventDialog;
