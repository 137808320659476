import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  Typography,
  Box,
  CssBaseline,
  Container,
} from '@mui/material';
import axios from 'axios';

interface CreateGroupDialogProps {
  open: boolean;
  onClose: () => void;
  onSuccess: (message: string) => void;
}

const CreateGroupDialog: React.FC<CreateGroupDialogProps> = ({ open, onClose, onSuccess }) => {
  const [failReason, setFailReason] = useState<string>('');

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const groupName = data.get('groupName') as string;
    const groupDescription = data.get('groupDescription') as string;

    if (!groupName || !groupDescription) {
      setFailReason('Please provide both group name and description.');
      return;
    }

    try {
      const response = await axios.post('/api/createGroup', { GroupName: groupName, GroupDescription: groupDescription }, {
        headers: {
          'Content-Type': 'application/json',
        },
        withCredentials: true,
      });

      if (response.status === 200) {
        onSuccess('Group created successfully!');
        onClose();
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          setFailReason(`${error.response.data.error || 'Unknown error'}`);
        } else if (error.request) {
          setFailReason('No response received from the server.');
        } else {
          setFailReason('Error in setting up the request.');
        }
      } else {
        setFailReason('Unexpected error occurred.');
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Create New Group</DialogTitle>
      <DialogContent>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="groupName"
              label="Group Name"
              name="groupName"
              type="text"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="groupDescription"
              label="Group Description"
              name="groupDescription"
              type="text"
            />
            {failReason && (
              <Typography color="error" variant="body2" align="center" sx={{ mt: 2 }}>
                {failReason}
              </Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              sx={{ mt: 3, mb: 2 }}
            >
              Create Group
            </Button>
          </Box>
        </Container>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateGroupDialog;
