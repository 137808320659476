import React, { ReactNode } from "react";
import Header from "./Header";
import Footer from "./Footer";
import { Container, Paper } from "@mui/material";
import "../CSS/Layout.css";

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <Container id="outer-container" disableGutters maxWidth={false}>
      <Header />
      <Paper square elevation={5} className="page-content">
        <div style={{ display: "grid" }}>
          {children}
        </div>
      </Paper>
      <Footer />
    </Container>
  );
};

export default Layout;
