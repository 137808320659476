import React from "react";
import { Typography, Container } from "@mui/material";

interface ComingSoonProps {
  page: string;
}

const ComingSoon: React.FC<ComingSoonProps> = ({ page }) => {
  return (
    <Container className="coming-soon">
      <Typography variant="h5" align="center">
        {page} - Coming Soon
      </Typography>
    </Container>
  );
};

export default ComingSoon;
