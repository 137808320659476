import React from "react";
import { IconButton, Tooltip, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import LoginIcon from '@mui/icons-material/Login';

interface SignInButtonProps {
    type: string
}
const SignInButton: React.FC<SignInButtonProps> = ({ type }) => {
    const navigate = useNavigate();

    function handleNavigation() {
        navigate(`/Login`);
    }
    if (type === "mobile") {
        return (
            <Tooltip title="Sign In" arrow>
                <IconButton onClick={handleNavigation}>
                    <LoginIcon />
                </IconButton>
            </Tooltip>
        );
    } else {
        return (
            <Button variant="contained" color="inherit" onClick={handleNavigation} sx={{ textTransform: 'none', color: 'inherit', mr: '8px', ml: '8px' }}>
                <LoginIcon sx={{ mr: 1 }} />
                <Typography>Sign in</Typography>
            </Button>
        )
    }
};

export default SignInButton;
