import React, { useState } from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography,
  CircularProgress
} from '@mui/material';
import axios from 'axios';

interface ActionDialogProps {
  open: boolean;
  onClose: () => void;
  selectedUsers: Set<number>;
  onActionSuccess: () => void;
  onActionFailure: () => void;
}

const DeleteSelectedUsersDialog: React.FC<ActionDialogProps> = ({ open, onClose, selectedUsers, onActionSuccess, onActionFailure }) => {
  const [loading, setLoading] = useState(false);

  const handleBulkDelete = async () => {
    if (selectedUsers.size === 0) return;
    setLoading(true);
    try {
      const userIds = Array.from(selectedUsers);
      const response = await axios.post(`/api/bulkDelete`, { userIds }, {
        headers: { 'Content-Type': 'application/json' },
        withCredentials: true,
      });
      if (response.status === 200) {
        onActionSuccess();
        setLoading(false);
      }
    } catch (error) {
        onActionFailure();
        setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby={`deleteSelected-dialog-title`}>
      <DialogTitle id={`deleteSelected-dialog-title`}>Delete Selected Users</DialogTitle>
      <DialogContent>
          <Typography>
            Are you sure you want to delete selected users?
          </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={loading}>
          Cancel
        </Button>
        <Button onClick={handleBulkDelete} color="primary" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteSelectedUsersDialog;
