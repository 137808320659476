import React, { useState } from 'react';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, Button, Typography,
  CircularProgress
} from '@mui/material';
import axios from 'axios';

interface ActionDialogProps {
  open: boolean;
  onClose: () => void;
  user: { id: number; username: string } | null;
  actionType: 'reset' | 'delete' | 'approve' | 'promote';
  onActionSuccess: () => void;
}

const actionConfig = {
  reset: {
    title: 'Reset Password',
    apiEndpoint: '/api/resetUserPassword',
    message: 'Are you sure you want to reset the password for'
  },
  delete: {
    title: 'Delete User',
    apiEndpoint: '/api/deleteUser',
    message: 'Are you sure you want to delete user'
  },
  approve: {
    title: 'Approve Admin Request',
    apiEndpoint: '/api/approveAdminRequest',
    message: 'Are you sure you want to approve the request for'
  },
  promote: {
    title: 'Promote User to Admin',
    apiEndpoint: '/api/promoteToAdmin',
    message: 'Are you sure you want to give admin permissions to'
  }
};

const ActionDialog: React.FC<ActionDialogProps> = ({ open, onClose, user, actionType, onActionSuccess }) => {
  const { title, apiEndpoint, message } = actionConfig[actionType];
  const [loading, setLoading] = useState(false);

  const handleAction = async () => {
    if (user) {
      setLoading(true);
      try {
        const response = await axios.post(apiEndpoint, { userID: user.id });
        if (response.status === 200) {
          onActionSuccess();
        }
      } catch (error) {
        console.error(`Error during ${actionType} action:`, error);
      } finally {
        setLoading(false);
        onClose();
      }
    }
  };

  return (
    <Dialog open={open} onClose={onClose} aria-labelledby={`${actionType}-dialog-title`}>
      <DialogTitle id={`${actionType}-dialog-title`}>{title}</DialogTitle>
      <DialogContent>
        {user && (
          <Typography>
            {message} <strong>{user.username}</strong>?
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" disabled={loading}>
          Cancel
        </Button>
        <Button onClick={handleAction} color="primary" disabled={loading}>
          {loading ? <CircularProgress size={24} /> : 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ActionDialog;
