import React, { ChangeEvent, FocusEvent, FormEvent, useEffect, useState } from 'react';
import { Button, Typography, Container, Box, Grid, TextField, Link, CssBaseline, IconButton, InputAdornment, Autocomplete, CircularProgress, Paper } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import axios from 'axios';
import encryptData from '../../Security/encryption';
import { useNavigate } from 'react-router-dom';

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  confirmPassword: string;
  companyName: string;
}

interface Blurred {
  firstName: boolean;
  lastName: boolean;
  email: boolean;
  password: boolean;
  confirmPassword: boolean;
  companyName: boolean;
}

interface Company {
  id: number;
  CompanyName: string;
}

const validateEmail = (email: string) => /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/.test(email);
const validatePassword = (password: string) => /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(password);

const SignUpForm: React.FC = () => {
  const [showPasswords, setShowPasswords] = useState({ password: false, confirmPassword: false });
  const [formValues, setFormValues] = useState<FormValues>({
    firstName: '', lastName: '', email: '', password: '', confirmPassword: '', companyName: ''
  });
  const [failReason, setFailReason] = useState('');
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [companyLoading, setCompanyLoading] = useState(false);
  const [blurred, setBlurred] = useState<Blurred>({
    firstName: false, lastName: false, email: false, password: false, confirmPassword: false, companyName: false
  });

  useEffect(() => {
    const fetchCompanies = async () => {
      setCompanyLoading(true);
      try {
        const response = await axios.get('/api/companies');
        if (response.data.success) {
          setCompanies(response.data.companies);
        } else {
          console.error('Failed to fetch companies:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching companies:', error);
      } finally {
        setCompanyLoading(false);
      }
    };
    fetchCompanies();
  }, []);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFormValues(prev => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const handleCompanyChange = (event: ChangeEvent<{}>, newValue: string | Company | null) => {
    setFormValues(prev => ({
      ...prev,
      companyName: typeof newValue === 'string' ? newValue : newValue?.CompanyName || ''
    }));
  };

  const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
    setBlurred(prev => ({ ...prev, [event.target.name]: true }));
  };

  const navigate = useNavigate();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    try {
      const encryptedData = await encryptData(formValues);
      await axios.post('/api/createAdmin', { encryptedData }, { headers: { 'Content-Type': 'application/json' } });
      navigate('/login', { state: { successMessage: 'Account successfully created.' } });
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setFailReason(error.response?.data.message || 'Unexpected error occurred.');
      } else {
        setFailReason('Unexpected error occurred.');
      }
    } finally {
      setLoading(false);
    }
  };

  const toggleShowPassword = (field: 'password' | 'confirmPassword') => {
    setShowPasswords(prev => ({ ...prev, [field]: !prev[field] }));
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box sx={{ marginTop: 8, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box sx={{ padding: 4, borderRadius: 2, boxShadow: 1, bgcolor: 'background.paper', width: '100%', maxWidth: 400 }}>
          <Typography component="h1" variant="h5">Sign up</Typography>
          <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
            <SignUpFields
              formValues={formValues}
              blurred={blurred}
              handleChange={handleChange}
              handleBlur={handleBlur}
              showPasswords={showPasswords}
              toggleShowPassword={toggleShowPassword}
              companies={companies}
              handleCompanyChange={handleCompanyChange}
              companyLoading={companyLoading}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              disabled={
                loading ||
                !formValues.firstName ||
                !formValues.lastName ||
                !validateEmail(formValues.email) ||
                !validatePassword(formValues.password) ||
                formValues.password !== formValues.confirmPassword ||
                !formValues.companyName
              }
            >
              {loading ? 'Signing Up...' : 'Sign Up'}
            </Button>
            <Typography color="error">{failReason}</Typography>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link href="/login" variant="body2">Already have an account? Sign in</Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

interface SignUpFieldsProps {
  formValues: FormValues;
  blurred: Blurred;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  handleBlur: (event: FocusEvent<HTMLInputElement>) => void;
  showPasswords: { password: boolean; confirmPassword: boolean };
  toggleShowPassword: (field: 'password' | 'confirmPassword') => void;
  companies: Company[];
  handleCompanyChange: (event: ChangeEvent<{}>, newValue: string | Company | null) => void;
  companyLoading: boolean;
}

const SignUpFields: React.FC<SignUpFieldsProps> = ({
  formValues,
  blurred,
  handleChange,
  handleBlur,
  showPasswords,
  toggleShowPassword,
  companies,
  handleCompanyChange,
  companyLoading
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <TextField
          autoComplete="given-name"
          name="firstName"
          required
          fullWidth
          id="firstName"
          label="First Name"
          autoFocus
          value={formValues.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          required
          fullWidth
          id="lastName"
          label="Last Name"
          name="lastName"
          autoComplete="family-name"
          value={formValues.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          id="email"
          label="Email Address"
          name="email"
          autoComplete="email"
          value={formValues.email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={blurred.email && !validateEmail(formValues.email)}
          helperText={blurred.email && !validateEmail(formValues.email) && 'Enter a valid email address'}
        />
      </Grid>
      <Grid item xs={12}>
        <Autocomplete
          freeSolo
          options={companies}
          getOptionLabel={(option) => typeof option === 'string' ? option : option.CompanyName}
          loading={companyLoading}
          onInputChange={handleCompanyChange}
          inputValue={formValues.companyName}
          PaperComponent={(props) => <Paper {...props} style={{ maxHeight: 200, overflow: 'auto' }} />}
          renderInput={(params) => (
            <TextField
              {...params}
              required
              fullWidth
              label="Company Name"
              name="companyName"
              autoComplete="company-name"
              onBlur={handleBlur}
              error={blurred.companyName && !formValues.companyName}
              helperText={blurred.companyName && !formValues.companyName && 'Company name is required'}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {companyLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          name="password"
          label="Password"
          type={showPasswords.password ? 'text' : 'password'}
          id="password"
          autoComplete="new-password"
          value={formValues.password}
          onChange={handleChange}
          onBlur={handleBlur}
          error={blurred.password && !validatePassword(formValues.password)}
          helperText={blurred.password && !validatePassword(formValues.password) && 'Password must contain at least 8 characters, one uppercase, one lowercase, one number, and one special character'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={() => toggleShowPassword('password')} edge="end">
                  {showPasswords.password ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          fullWidth
          name="confirmPassword"
          label="Confirm Password"
          type={showPasswords.confirmPassword ? 'text' : 'password'}
          id="confirmPassword"
          autoComplete="new-password"
          value={formValues.confirmPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          error={blurred.confirmPassword && formValues.password !== formValues.confirmPassword}
          helperText={blurred.confirmPassword && formValues.password !== formValues.confirmPassword && 'Passwords do not match'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" onClick={() => toggleShowPassword('confirmPassword')} edge="end">
                  {showPasswords.confirmPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};

export default SignUpForm;
