import React from "react";
import Layout from "../Layout/Layout";
import LoginForm from "../Components/UserAuth/LoginForm";

const Login: React.FC = () => {
  return (
    <Layout>
      <LoginForm/>
    </Layout>
  );
};

export default Login;
