import React, { useState, ChangeEvent } from 'react';
import { Box, TextField, Button } from '@mui/material';

interface TextInputProps {
  onData: (data: string) => void;
}

const TextInput: React.FC<TextInputProps> = ({ onData }) => {
  const [text, setText] = useState('');

  const handleTextChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setText(event.target.value);
  };

  const handleBlur = () => {
    onData(text);
  };

  const handleClearText = () => {
    setText('');
    onData('');
  };

  return (
    <Box>
      <TextField
        label="Enter Names, Emails"
        variant="outlined"
        multiline
        value={text}
        onChange={handleTextChange}
        onBlur={handleBlur}
        fullWidth
      />
      <Box mt={2}>
        <Button onClick={handleClearText} variant="outlined" color="secondary">
          Clear Text
        </Button>
      </Box>
    </Box>
  );
};

export default TextInput;
