import React from "react";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

interface HeaderButtonProps {
    path: string,
    name: string
}
const HeaderButton: React.FC<HeaderButtonProps> = ({ path, name }) => {
    const navigate = useNavigate();

    function handleNavigation() {
        navigate(`/${path}`)
    }

    return (
        <Button variant="contained" color="inherit" onClick={() => { handleNavigation(); }} sx={{ textTransform: 'none', color: 'inherit', mr: '8px', ml: '8px' }}>
            <Typography>{name}</Typography>
        </Button>
    );
};

export default HeaderButton
