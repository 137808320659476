import React, { useState, ChangeEvent } from 'react';
import { Box, Button, Typography, IconButton } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DeleteIcon from '@mui/icons-material/Delete';

interface CSVFileInputProps {
  onData: (data: string) => void;
}

const CSVFileInput: React.FC<CSVFileInputProps> = ({ onData }) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleFileChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    setSelectedFile(file);

    try {
      const csvData = await readFile(file);
      onData(csvData);
    } catch (error) {
      console.error('Error reading file:', error);
    }
  };

  const readFile = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target?.result) {
          resolve(event.target.result.toString());
        } else {
          reject(new Error('Failed to read file.'));
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsText(file);
    });
  };

  const handleClearFile = () => {
    setSelectedFile(null);
    onData('');
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      {selectedFile ? (
        <Box display="flex" alignItems="center" justifyContent="space-between" sx={{ width: '100%' }}>
          <Typography variant="body1" sx={{ maxWidth: '80%' }}>
            {selectedFile.name}
          </Typography>
          <IconButton onClick={handleClearFile} color="secondary">
            <DeleteIcon />
          </IconButton>
        </Box>
      ) : (
        <label htmlFor="file-input">
          <input
            id="file-input"
            type="file"
            accept=".csv"
            onChange={handleFileChange}
            style={{ display: 'none' }}
          />
          <Button variant="contained" component="span" startIcon={<CloudUploadIcon />}>
          Upload CSV File
          </Button>
        </label>
      )}
    </Box>
  );
};

export default CSVFileInput;
