import React from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../Layout/Layout';
import { Box, Typography, Button } from '@mui/material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';

const Error404Page: React.FC = () => {
    const navigate = useNavigate();

    const handleNavigation = (): void => {
        navigate('/');
    };

    return (
        <Layout>
            <Box
                mt={5}
                textAlign="center"
                display="flex"
                flexDirection="column"
                alignItems="center"
            >
                <WarningAmberIcon
                    sx={{ fontSize: 80, mb: 2 }}
                    aria-label="Error icon"
                />
                <Typography variant="h4" component="h1" gutterBottom>
                    Error 404 - Page Not Found
                </Typography>
                <Typography variant="body1" color="textSecondary" paragraph>
                    Sorry, the page you are looking for doesn’t exist. It might have been moved or deleted.
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNavigation}
                    sx={{ mt: 3 }}
                >
                    Go to Home Page
                </Button>
            </Box>
        </Layout>
    );
};

export default Error404Page;
