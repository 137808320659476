import React from 'react';
import { IconButton, Grid, Menu, MenuItem, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import ThemeToggle from './ThemeToggle';
import SignOutButton from './Buttons/SignOutButton';
import SignInButton from './Buttons/SignInButton';
import Logo from './Logo';

interface PagesProps {
  path: string;
  name: string;
}

interface MobileMenuProps {
  navMenu: HTMLElement | null;
  handleOpenNavMenu: (event: React.MouseEvent<HTMLButtonElement>) => void;
  handleCloseNavMenu: () => void;
  pages: PagesProps[];
  toggleColorMode: () => void;
  theme: any;
  isAuthenticated: boolean;
}

const MobileMenu: React.FC<MobileMenuProps> = ({
  navMenu,
  handleOpenNavMenu,
  handleCloseNavMenu,
  pages,
  toggleColorMode,
  theme,
  isAuthenticated,
}) => {
  const navigate = useNavigate();

  const handleNavigation = (page: string) => {
    navigate(`/${page.toLowerCase() === 'home' ? '' : page}`);
    handleCloseNavMenu();
  };

  return (
    <Grid container alignItems="center" sx={{ display: { xs: 'flex', sm: 'none' }, width: '100%' }}>
      <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
        <IconButton
          aria-label="Navigation menu"
          onClick={handleOpenNavMenu}
          color="inherit"
        >
          <MenuIcon />
        </IconButton>
      </Grid>
      <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center' }}>
        <Logo theme={theme} />
      </Grid>
      <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        {isAuthenticated && <SignOutButton type="mobile" />}
        {!isAuthenticated && <SignInButton type="mobile" />}
        <ThemeToggle toggleColorMode={toggleColorMode} theme={theme} />
      </Grid>
      <Menu
        anchorEl={navMenu}
        open={Boolean(navMenu)}
        onClose={handleCloseNavMenu}
      >
        {pages.map((page) => (
          <MenuItem key={page.name} onClick={() => handleNavigation(page.path)}>
            <Typography textAlign="center">{page.name}</Typography>
          </MenuItem>
        ))}
      </Menu>
    </Grid>
  );
};

export default MobileMenu;
