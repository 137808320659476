import React from "react";
import Layout from "../Layout/Layout";
import ComingSoon from "../Components/ComingSoon";

const Home: React.FC = () => {

  return (
    <Layout>
      <ComingSoon page="Drink Tokens App" />
    </Layout>
  );
};

export default Home;
