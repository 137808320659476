import React from 'react';
import Layout from '../Layout/Layout';
import { Box, Button, Typography, Card, CardContent, Grid, Divider } from '@mui/material';
import { Link } from 'react-router-dom';

const Admin: React.FC = () => {
  return (
    <Layout>
      <Box mt={3} textAlign="center">
        <Typography variant="h4" gutterBottom>
          Admin Dashboard
        </Typography>
        <Typography variant="subtitle1" paragraph>
          Manage users, groups, and events with the tools below. Select a section to get started.
        </Typography>

        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={4}>
            <Card sx={{ height: '100%', p: 2, ml:'20px' }}>
              <CardContent>
                <Typography variant="h6">User Management</Typography>
                <Typography paragraph>
                  Create new user accounts and manage existing ones. Update user details, generate passwords, and send login information.
                </Typography>
                <Link to="/admin/userManagement">
                  <Button variant="outlined" color="primary">
                    Manage Users
                  </Button>
                </Link>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card sx={{ height: '100%', p: 2 }}>
              <CardContent>
                <Typography variant="h6">Group Management</Typography>
                <Typography paragraph>
                  Add, remove, and manage users within groups. Move users between different groups as needed.
                </Typography>
                <Link to="/admin/groupManagement">
                  <Button variant="outlined" color="primary">
                    Manage Groups
                  </Button>
                </Link>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card sx={{ height: '100%', p: 2, mr:'20px' }}>
              <CardContent>
                <Typography variant="h6">Event Management</Typography>
                <Typography paragraph>
                  Create and manage events with details such as event name, date, and description. Manage token types and amounts.
                </Typography>
                <Link to="/admin/eventManagement">
                  <Button variant="outlined" color="primary">
                    Manage Events
                  </Button>
                </Link>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* Upcoming Event Widget Placeholder */}
        <Box mt={5} textAlign="center">
          <Typography variant="h5" gutterBottom>
            Upcoming Event
          </Typography>
          <Typography variant="body1" paragraph>
            Stay tuned for details on our next event.
          </Typography>
          <Card sx={{ maxWidth: 600, mx: 'auto', p: 3 }}>
            <CardContent>
              <Typography variant="h6">Event Countdown</Typography>
              <Divider sx={{ my: 2 }} />
              <Typography paragraph>
                {/* Placeholder content for the countdown timer */}
                The countdown timer will be displayed here. This is where you can show the time remaining until the event starts.
              </Typography>
              <Typography variant="body2" color="textSecondary">
                Event Date: [To be updated]
              </Typography>
            </CardContent>
          </Card>
        </Box>
        
      </Box>
    </Layout>
  );
};

export default Admin;
