import React, { useCallback, useEffect, useState } from 'react';
import axios from 'axios';
import { Dialog, DialogTitle, DialogContent, DialogActions, Table, TableHead, TableRow, TableCell, TableBody, Button, Typography, Container, Box, Tooltip, IconButton } from '@mui/material';
import { Delete, TransferWithinAStation, GroupAdd } from '@mui/icons-material';
import DeleteFromGroupDialog from './DeleteFromGroupDialog';
import TransferToGroupDialog from './TransferToGroupDialog';
import AddToGroupDialog from './AddToGroupDialog';

interface User {
  UserID: number;
  username: string;
  email: string;
  group?: boolean;
}

interface Group {
  GroupID: number;
  GroupName: string;
  GroupDescription: string;
}

interface ViewUsersDialogProps {
  open: boolean;
  groupID: number | null | "All";
  groups: Group[];
  onClose: () => void;
  onSuccess: (message: string) => void;
}

const ViewUsersDialog: React.FC<ViewUsersDialogProps> = ({
  open,
  groupID,
  groups,
  onClose,
  onSuccess
}) => {
  const [users, setUsers] = useState<User[]>([]);
  const [failReason, setFailReason] = useState<string>('');
  const [user, setUser] = useState<{ UserID: number; username: string }>({ UserID: 0, username: '' });
  const [transferDialogOpen, setTransferDialogOpen] = useState<boolean>(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
  const [addUserToGroupDialogOpen, setAddUserToGroupDialogOpen] = useState<boolean>(false);

  const fetchUsers = useCallback(async () => {
    try {
      let response = null;
      if (groupID === null) {
        response = await axios.get('/api/unassignedUsers', { withCredentials: true });
      } else if (groupID === "All") {
        response = await axios.get('/api/allUsers', { withCredentials: true });
      } else {
        response = await axios.post('/api/groupUsers', { groupID }, { withCredentials: true });
      }
      if (response.status === 200) {
        setUsers(response.data.groupUsers);
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        if (error.response) {
          setFailReason(`${error.response.data.error || 'Unknown error'}`);
        } else if (error.request) {
          setFailReason('No response received from the server.');
        } else {
          setFailReason('Error in setting up the request.');
        }
      } else {
        setFailReason('Unexpected error occurred.');
      }
    }
  }, [groupID]);

  useEffect(() => {
    if (open) {
      fetchUsers();
    }
  }, [open, fetchUsers]);

  const handleOpenTransferDialog = (user: { UserID: number; username: string }) => {
    setUser(user);
    setTransferDialogOpen(true);
  };

  const handleOpenDeleteDialog = (user: { UserID: number; username: string }) => {
    setUser(user);
    setDeleteDialogOpen(true);
  };

  const handleCloseTransferDialog = () => {
    fetchUsers();
    setTransferDialogOpen(false);
  }

  const handleCloseDeleteDialog = () => {
    fetchUsers();
    setDeleteDialogOpen(false);
  }

  const handleOpenAddUserToGroupDialog = (user: { UserID: number; username: string }) => {
    setUser(user);
    setAddUserToGroupDialogOpen(true);
  };

  const handleCloseAddUserToGroupDialog = () => {
    fetchUsers();
    setAddUserToGroupDialogOpen(false);
  }

  const getTitle = () => {
    if (groupID === null) return 'Unassigned Users';
    if (groupID === "All") return "All Users";
    const group = groups.find((group) => group.GroupID === groupID);
    return `Users in ${group?.GroupName}:`;
  };

  const getGroupCell = (user: { UserID: number, username: string, group?: boolean, email: string }) => {
    if (groupID === "All") return <TableCell>{user.group ? "True" : "False"}</TableCell>;
    const group = groups.find((group) => group.GroupID === groupID);
    return <TableCell>{group?.GroupName || "Unassigned"}</TableCell>;
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <DialogTitle>{getTitle()}</DialogTitle>
        <DialogContent>
          <Container component="main" sx={{ display: 'flex', justifyContent: 'center', padding: 2 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Username</TableCell>
                  <TableCell>Email</TableCell>
                  {groupID === "All" ? <TableCell>In Group?</TableCell> : <TableCell>Group</TableCell>}
                  <TableCell align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user) => (
                  <TableRow key={user.UserID} hover>
                    <TableCell>{user.username}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    {getGroupCell(user)}
                    <TableCell align="right">
                      <Box display="flex" justifyContent="flex-end" gap={1}>
                      <Tooltip title="Add User to Another Group" arrow>
                          <IconButton
                            size="small"
                            color="secondary"
                            onClick={() => handleOpenAddUserToGroupDialog(user)}
                            aria-label="Add User to Another Group"
                          >
                            <GroupAdd />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Transfer User to Another Group" arrow>
                          <IconButton
                            size="small"
                            color="primary"
                            onClick={() => handleOpenTransferDialog(user)}
                            aria-label="Transfer Group"
                          >
                            <TransferWithinAStation />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete from Group" arrow>
                          <IconButton
                            size="small"
                            color="error"
                            onClick={() => handleOpenDeleteDialog(user)}
                            aria-label="Delete from Group"
                          >
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {failReason && (
              <Typography color="error" variant="body2" align="center" sx={{ mt: 2 }}>
                {failReason}
              </Typography>
            )}
          </Container>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center", padding: 2 }}>
          <Button onClick={onClose} variant="contained" color="secondary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <TransferToGroupDialog
        open={transferDialogOpen}
        onClose={handleCloseTransferDialog}
        user={user}
        groups={groups}
        onSuccess={onSuccess}
        groupID={groupID as number}
      />

      <DeleteFromGroupDialog
        open={deleteDialogOpen}
        onClose={handleCloseDeleteDialog}
        user={user}
        groupID={groupID as number}
        onSuccess={onSuccess}
      />

      <AddToGroupDialog
        open={addUserToGroupDialogOpen}
        onClose={handleCloseAddUserToGroupDialog}
        user={user}
        groups={groups}
        onSuccess={onSuccess}
      />
    </>
  );
};

export default ViewUsersDialog;
