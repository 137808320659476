import React from "react";
import Layout from "../Layout/Layout";
import SignUpForm from "../Components/UserAuth/SignUpForm";

const SignUp: React.FC = () => {
  return (
    <Layout>
      <SignUpForm/>
    </Layout>
  );
};

export default SignUp;
