import React, { useState, useEffect, useCallback } from 'react';
import { Box, Typography, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper } from '@mui/material';

interface DataTableProps {
  data: string;
  setData: (userData: { name: string; email: string }[]) => void;
}

const parseData = (data: string): { name: string; email: string }[] => {
  const lines = data.split(/\r\n|\n/);
  const validData = lines
    .filter((line) => line.trim() !== "")
    .map((line) => {
      if (line.includes(",")) {
        const [name, email] = line.split(",");
        return { name: name.trim(), email: email.trim() };
      } else {
        return { name: line, email: "" };
      }
    });
  return validData;
};

const DataTable: React.FC<DataTableProps> = ({ data, setData }) => {
  const [parsedData, setParsedData] = useState<{ name: string; email: string }[]>([]);
  const [invalidLines, setInvalidLines] = useState<string[]>([]);
  const [validData, setValidData] = useState<{ name: string; email: string }[]>([]);
  const updateData = useCallback(
    (data: string) => {
      const parsedData = parseData(data);
      const validData = parsedData.filter((row) => row.name && row.email);
      const invalidLines = parsedData
        .filter((row) => !row.name || !row.email)
        .map((row) => `${row.name}, ${row.email}`);

      setParsedData(validData);
      setInvalidLines(invalidLines);
      setValidData(validData)
      setData(validData);
    },
    [setData, setValidData]
  );

  useEffect(() => {
    if (validData.length === 0 && data !== '') {
      updateData(data);
    }
    else if (validData.length > 0 && data === '') {
      updateData('')
    }
    else if (validData.length > 0 && data !== '') {
      updateData(data)
    }
  }, [validData, data, updateData]);


  return (
    <Box>
      {invalidLines.length > 0 && (
        <Typography variant="body1" color="error">
          Some lines are not in the format 'name, email': {invalidLines.join(', ')}
        </Typography>
      )}
      {parsedData.length > 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Email</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {parsedData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.email}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography variant="body1">No valid names and emails found.</Typography>
      )}
    </Box>
  );
};

export default DataTable;
