import React from 'react';
import { Box } from '@mui/material';
import Logo from './Logo';
import ThemeToggle from './ThemeToggle';
import HeaderButton from './Buttons/HeaderButton';
import SignOutButton from './Buttons/SignOutButton';
import SignInButton from './Buttons/SignInButton';
import SignUpButton from './Buttons/SignUpButton';

interface PagesProps {
  path: string,
  name: string
}

interface DesktopMenuProps {
  pages: PagesProps[];
  toggleColorMode: () => void;
  theme: any;
  isAuthenticated: boolean;
}

const DesktopMenu: React.FC<DesktopMenuProps> = ({ pages, toggleColorMode, theme, isAuthenticated }) => {

  return (
    <Box
      sx={{ display: { xs: 'none', sm: 'flex' } }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexGrow: 1
        }}
      >
        <Logo theme={theme} />
        {pages.map((page) => (
          <HeaderButton key={page.name} path={page.path} name={page.name} />
        ))}
      </Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center'
        }}
      >
        {isAuthenticated ? (
          <SignOutButton type="desktop" />
        ) : (
          <>
            <SignInButton type="desktop" />
            <SignUpButton />
          </>
        )}
        <ThemeToggle toggleColorMode={toggleColorMode} theme={theme} />
      </Box>
    </Box>
  );
};

export default DesktopMenu;